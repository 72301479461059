/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { BASE_SIZE, ClassNames, ClassProps, createGetNames, TEXT_RATIO } from './text.class'

const sizes = {
  xl: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 12,
  lg: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 11,
  md: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 10,
  sm: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 9,
  xs: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 8,
}

export const titleClass = new StyleClass<ClassNames, ClassProps>(createGetNames(sizes))
