import { FC, useLayoutEffect } from 'react'
import { classes, stylesheet, style } from 'typestyle'

/* Hooks ======================================================================================== */
import useFetch from '../../../__aeki__/hooks/use-fetch'
import useValue from '../../../__aeki__/hooks/use-value'

/* Components =================================================================================== */
import { Box } from '../../../__aeki__/components/box/box.component'
import { Text } from '../../../__aeki__/components/text/text.component'
import { A } from '../../../__aeki__/components/a'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../../../__aeki__/components/style-class/style-class.utils'
import { m0mdi } from '../../../__aeki__/styles/styleset/margin/m0mdi'
import { mb16 } from '../../../__aeki__/styles/styleset/margin/mb16'
import { mb20 } from '../../../__aeki__/styles/styleset/margin/mb20'
import { ml24 } from '../../../__aeki__/styles/styleset/margin/ml24'
import { mr0smi } from '../../../__aeki__/styles/styleset/margin/mr0smi'
import { mr24 } from '../../../__aeki__/styles/styleset/margin/mr24'
import { mr48 } from '../../../__aeki__/styles/styleset/margin/mr48'
import { mt36 } from '../../../__aeki__/styles/styleset/margin/mt36'
import { mt8 } from '../../../__aeki__/styles/styleset/margin/mt8'
import { mt8mdi } from '../../../__aeki__/styles/styleset/margin/mt8mdi'
import { pb12 } from '../../../__aeki__/styles/styleset/padding/pb12'
import { pl0 } from '../../../__aeki__/styles/styleset/padding/pl0'
import { primaryDarki } from '../../../__aeki__/styles/styleset/primary-dark/primaryDarki'
import { pt8 } from '../../../__aeki__/styles/styleset/padding/pt8'
import { w100 } from '../../../__aeki__/styles/styleset/width/w100'
import { mb20mdi } from '../../../__aeki__/styles/styleset/margin/mb20mdi'

/* Constants ==================================================================================== */
import { MAX_WIDTH } from '../layout'
import { paddingVerticalOuter } from '../../../__aeki__/styles/default/padding'
import { useMemo } from 'react'
import { mr40 } from '../../../__aeki__/styles/styleset/margin/mr40'

/* Types ======================================================================================== */
type FooterType = {
  list?: { title: string; pages: { title: string; to: string }[] }[]
  isGroupSite?: boolean
  style?: any
}

/* Constants ==================================================================================== */
const FOOTER_ITEMS = [
  {
    title: 'ChildAt.io',
    className: classes(
      mr48,
      style({
        minWidth: 100,
        ...createMediaQuery['md']({
          maxWidth: '100%',
        }),
      }),
    ),
    pages: [
      {
        title: 'Home',
        to: '/',
      },
      {
        title: 'About us',
        to: '/a/p/about-us',
      },
      {
        title: 'Terms & Policy',
        to: '/a/p/policy',
      },
      {
        title: 'Facebook',
        to: 'https://www.facebook.com/Childatio-107822458506156',
        target: '_blank',
      },
      {
        title: 'Instagram',
        to: 'https://www.instagram.com/childat.io/',
        target: '_blank',
      },
      {
        title: 'LinkedIn',
        to: 'https://www.linkedin.com/company/childat-io',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Childcare',
    className: classes(
      ml24,
      mr24,
      style({
        maxWidth: 320,
        ...createMediaQuery['md']({
          maxWidth: '100%',
        }),
      }),
    ),
    pages: [
      {
        title: 'Downtown, Vancouver, British Columbia, Canada',
        to: '/a/childcares/central-vancouver_vancouver_british-columbia_canada',
      },
      {
        title: 'UBC, Vancouver, British Columbia, Canada',
        to: '/a/childcares/ubc_vancouver_british-columbia_canada',
      },
      {
        title: 'Kitsilano, Vancouver, British Columbia, Canada',
        to: '/a/childcares/kitsilano_vancouver_british-columbia_canada',
      },
      {
        title: 'Browse all places',
        to: '/a/places',
      },
    ],
  },
  {
    title: 'Resources',
    className: classes(
      ml24,
      mr40,
      style({
        minWidth: 102,
      }),
    ),
    pages: [
      {
        title: 'Blog for parents',
        to: '/a/blog',
      },
      {
        title: 'Documentation',
        to: '/a/docs',
      },
    ],
  },
  {
    title: 'Support',
    className: classes(
      ml24,

      style({
        minWidth: 140,
      }),
    ),
    pages: [
      {
        title: 'Contact us',
        to: '/a/contact-us',
      },
      {
        title: 'Claim your Childcare',
        to: '/a/claim-your-childcare',
      },
    ],
  },
]

/* <Footer /> =================================================================================== */
const Footer: FC<FooterType> = ({ isGroupSite, style }) => {
  const toReturn = useMemo(
    () => (
      <Box
        style={style}
        className={classes(isGroupSite ? '' : '', isGroupSite ? '' : '', pb12)}
        baseline="background"
        row
        fullWidth
      >
        <Box maxWidth={MAX_WIDTH} centerAutoX baseline={'none'} fullWidth>
          <FooterContent isGroupSite={isGroupSite} />
        </Box>
      </Box>
    ),
    [],
  )
  return toReturn
}

/* <FooterContent /> ============================================================================ */
export const FooterContent = (props: {
  row?: boolean
  className?: string
  isGroupSite?: boolean
}) => {
  const { row, className } = props

  const toReturn = useMemo(() => {
    return (
      <Box baseline="background">
        {!props.isGroupSite && (
          <Box
            className={classes(paddingVerticalOuter, STYLES.containerFooterContent, className)}
            baseline="background"
            maxWidth={880}
            row={row}
            centerAutoX
          >
            {FOOTER_ITEMS.map((menu: any, key: any) => {
              if (menu.pages.length > 0) {
                return (
                  <Box
                    className={classes(
                      STYLES.containerFooterItem,
                      mr0smi,
                      w100,
                      mb20,
                      m0mdi,
                      mb20mdi,
                      menu.className,
                    )}
                    baseline="background"
                    key={key}
                  >
                    <Text
                      className={STYLES.textFooterItemTitle}
                      baseline="surface"
                      size="xs"
                      semiBold
                      uppercase
                      // lighter
                    >
                      {menu.title}
                    </Text>
                    <ul className={classes(mt8, pl0)}>
                      {menu.pages.map((page: any, key: number) => {
                        return (
                          <Text
                            className={classes(mb16, STYLES.textFooterItem)}
                            size="sm"
                            tag={'li'}
                            key={key}
                          >
                            <a href={(page as any).to} target={page.target}>
                              {(page as any).title}
                            </a>
                          </Text>
                        )
                      })}
                    </ul>
                  </Box>
                )
              }
            })}
          </Box>
        )}
        <Box baseline="background">
          {props.isGroupSite && (
            <Box
              className={classes(mt36, mt8mdi, pt8, STYLES.textCopyRight)}
              baseline="none"
              maxWidth={MAX_WIDTH}
              centerAutoX
            >
              <Text size="sm">
                This page is provided by{' '}
                <Text link>
                  <A to="/" title="Go to ChildAt.io Home Page" className={primaryDarki}>
                    ChildAt.io
                  </A>
                </Text>
              </Text>
            </Box>
          )}
          <Box
            className={classes(mt36, mt8mdi, pt8, STYLES.textCopyRight)}
            baseline="none"
            maxWidth={MAX_WIDTH}
            centerAutoX
          >
            <Text size="sm" shrink baseline="surface">
              © 2023 ChildAt.io. All rights reserved.
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }, [])

  return toReturn
}

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  containerFooterContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',

    $nest: {
      ...createMediaQuery['md']({
        margin: 0,
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }),
      '& a': {},
    },
  },
  containerFooterItem: {
    width: 'fit-content',
  },
  textFooterItemTitle: {
    whiteSpace: 'nowrap',
  },
  textFooterItem: {
    display: 'block',
    opacity: 0.7,
    $nest: {
      '&:hover': {
        opacity: 1,
        transition: 'opacity 0.1s ease-in-out',
      },
    },
  },
  textCopyRight: {
    textAlign: 'center',
  },
})

export default Footer
