import { useEffect, cloneElement } from 'react'
import { stylesheet, classes } from 'typestyle'
import VisibilitySensor from 'react-visibility-sensor'

import useValue from '../../../../__aeki__/hooks/use-value'
import { Box } from '../../../../__aeki__/components/box/box.component'
import { useCallback } from 'react'

export const VisibleFade = (props: {
  children: any
  delay?: number
  direction?: 'left' | 'right' | 'top' | 'bottom'
  hard?: boolean
}) => {
  // const [trigger, $trigger] = useValue()
  const [cache, $cache] = useValue()

  let conditions: any = {
    transformBefore: 'translateY(-40px)',
    transformAfter: 'translateY(0px)',
  }

  switch (props.direction) {
    case 'left': {
      conditions = {
        transformBefore: 'translateX(-40px)',
        transformAfter: 'translateX(0px)',
      }
    }
    case 'right': {
      conditions = {
        transformBefore: 'translateX(10px)',
        transformAfter: 'translateX(0px)',
      }
    }
    case 'bottom': {
      conditions = {
        transformBefore: 'translateY(10px)',
        transformAfter: 'translateY(0px)',
      }
    }
  }

  const componentProps = useCallback(
    (isVisible: boolean) => ({
      style: {
        // opacity: 0,
        transition: 'transform .8s ease',
        transitionProperty: 'transform, opacity',
        transform: isVisible ? conditions.transformAfter : conditions.transformBefore,
        opacity: isVisible ? 1 : 0,
      },
    }),
    [],
  )

  const content = useCallback(
    ({ isVisible }) => {
      return (
        <Box baseline="none" {...componentProps(isVisible)}>
          {props.children}
        </Box>
      )
    },
    [props.children],
  )

  if (props.hard) {
    return (
      <VisibilitySensor>
        {({ isVisible }) => {
          if (isVisible) {
            return <VisibilitySensor partialVisibility>{content}</VisibilitySensor>
          } else {
            return <span style={{ visibility: 'hidden' }}>VISIBLE TEMP</span>
          }
        }}
      </VisibilitySensor>
    )
  }

  return <VisibilitySensor partialVisibility>{content}</VisibilitySensor>
}
