/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { BASE_SIZE, ClassNames, ClassProps, createGetNames, TEXT_RATIO } from './text.class'

const sizes = {
  xl: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 7,
  lg: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 6,
  md: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 5,
  sm: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 4,
  xs: BASE_SIZE + BASE_SIZE * TEXT_RATIO * 3,
}

export const headingClass = new StyleClass<ClassNames, ClassProps>(createGetNames(sizes))
