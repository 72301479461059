import { cloneElement, FC, ReactElement, useEffect } from 'react'
import { stylesheet, classes } from 'typestyle'
import VisibilitySensor from 'react-visibility-sensor'

import useBoolean from '../../../__aeki__/hooks/use-boolean'

import { Box } from '../../../__aeki__/components/box/box.component'
import useValue from '../../../__aeki__/hooks/use-value'

export const Visible: FC<{ className?: string; children: ReactElement }> = ({
  children,
  className,
}) => {
  const [isVisible, $isVisible] = useBoolean(false)

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible: any) => {
        $isVisible.set(isVisible)
      }}
    >
      <div className={classes(STYLES.container, isVisible && STYLES.containerShow, className)}>
        {children}
      </div>
    </VisibilitySensor>
  )
}

export const VisibleBackgroundImageBox = (props: any) => {
  const [isVisible, $isVisible] = useBoolean(false)

  const boxProps = { ...props }

  if (!isVisible) {
    delete boxProps.backgroundImage
  }

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible: any) => {
        $isVisible.set(isVisible)
      }}
    >
      <Box
        {...boxProps}
        className={classes(boxProps.className, STYLES.container, isVisible && STYLES.containerShow)}
      />
    </VisibilitySensor>
  )
}

const STYLES = stylesheet({
  container: {
    transition: 'opacity, transform 0.2s ease-in-out !important',
    opacity: 0,
    transform: 'translateY(0px)',
  },
  containerShow: {
    transition: 'opacity, transform 0.2s ease-in-out !important',
    opacity: 1,
    transform: 'translateY(0px)',
  },
})

export const VisibleFade = (props: {
  children: any
  delay?: number
  direction?: 'left' | 'right' | 'top' | 'bottom'
  hard?: boolean
}) => {
  const [trigger, $trigger] = useValue()
  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        $trigger.set(true)
      }, props.delay)
    } else {
      $trigger.set(true)
    }
  }, [])

  let conditions: any = {
    transformBefore: 'translateY(-10px)',
    transformAfter: 'translateY(0px)',
  }

  switch (props.direction) {
    case 'left': {
      conditions = {
        transformBefore: 'translateX(-10px)',
        transformAfter: 'translateX(0px)',
      }
    }
    case 'right': {
      conditions = {
        transformBefore: 'translateX(10px)',
        transformAfter: 'translateX(0px)',
      }
    }
    case 'bottom': {
      conditions = {
        transformBefore: 'translateY(10px)',
        transformAfter: 'translateY(0px)',
      }
    }
  }

  if (props.hard) {
    return (
      <VisibilitySensor>
        {({ isVisible }) => {
          if (isVisible) {
            return cloneElement(props.children, {
              ...props.children.props,
              style: {
                ...props.children.style,
                transition: 'transform .8s ease',
                transitionProperty: 'transform, opacity',
                transform:
                  isVisible && trigger ? conditions.transformAfter : conditions.transformBefore,
                opacity: isVisible && trigger ? 1 : 0,
              },
            })
          } else {
            return <span style={{ visibility: 'hidden' }}>VISIBLE TEMP</span>
          }
        }}
      </VisibilitySensor>
    )
  }

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return cloneElement(props.children, {
          ...props.children.props,
          style: {
            ...props.children.style,
            transition: 'transform .8s ease',
            transitionProperty: 'transform, opacity',
            transform:
              isVisible && trigger ? conditions.transformAfter : conditions.transformBefore,
            opacity: isVisible && trigger ? 1 : 0,
          },
        })
      }}
    </VisibilitySensor>
  )
}

export const VisibleSize = (props: {
  children: any
  delay?: number
  dimension?: 'width' | 'height'
}) => {
  const [trigger, $trigger] = useValue()
  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        $trigger.set(true)
      }, props.delay)
    } else {
      $trigger.set(true)
    }
  }, [])

  let conditions: any = {
    widthBefore: '0%',
    widthAfter: '100%',
  }

  switch (props.dimension) {
    case 'height': {
      conditions = {
        heightBefore: '0%',
        heightAfter: '100%',
      }
    }
  }

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return cloneElement(props.children, {
          ...props.children.props,
          style: {
            ...props.children.style,
            transition: '.8s ease',
            transitionProperty: `${props.dimension}, opacity`,
            width:
              props.dimension === 'width'
                ? isVisible && trigger
                  ? conditions.widthAfter
                  : conditions.widthBefore
                : 'initial',
            height:
              props.dimension === 'height'
                ? isVisible && trigger
                  ? conditions.heightAfter
                  : conditions.heightBefore
                : 'initial',
            opacity: isVisible && trigger ? 1 : 0,
          },
        })
      }}
    </VisibilitySensor>
  )
}

export const VisibleOpacity = (props: { children: any; delay?: number }) => {
  const [trigger, $trigger] = useValue()
  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        $trigger.set(true)
      }, props.delay)
    } else {
      $trigger.set(true)
    }
  }, [])

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return cloneElement(props.children, {
          ...props.children.props,
          style: {
            ...props.children.style,
            transition: '.4s ease',
            transitionProperty: 'opacity',
            opacity: isVisible && trigger ? 1 : 0.5,
          },
        })
      }}
    </VisibilitySensor>
  )
}
