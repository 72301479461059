import { FC, useEffect, useMemo, useRef } from 'react'
import { stylesheet, classes, keyframes, style, cssRaw } from 'typestyle'
import { Link, useNavigate } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useValue from '../../__aeki__/hooks/use-value'
import useCounter from '../../__aeki__/hooks/use-counter'
import useFetch from '../../__aeki__/hooks/use-fetch'

/* Components =================================================================================== */
import AppLayout, { MAX_WIDTH } from './layout'
import Footer from './components/footer'
import ReactVisibilitySensor from 'react-visibility-sensor'
import Skeleton from '../../__aeki__/components/skeleton'
import { Box } from '../../__aeki__/components/box/box.component'
import { Button } from '../../__aeki__/components/button'
import { Heading } from '../../__aeki__/components/text/heading.component'
import { Line } from '../../__aeki__/components/line'
import { Text } from '../../__aeki__/components/text/text.component'
import { Title } from '../../__aeki__/components/text/title.component'
import { VisibleFade } from './components/visible/visible-fade'
import { VisibleSize } from './components/visibile'

/* Styles ======================================================================================= */
import {
  createCustomMediaQuery,
  createMediaQuery,
} from '../../__aeki__/components/style-class/style-class.utils'
import { paddingVerticalOuter } from '../../__aeki__/styles/default/padding'
import { f18i } from '../../__aeki__/styles/styleset/font-size/f18i'
import { w54 } from '../../__aeki__/styles/styleset/width/w54'
import { w100md } from '../../__aeki__/styles/styleset/width/w100md'
import { mb40 } from '../../__aeki__/styles/styleset/margin/mb40'
import { mt40 } from '../../__aeki__/styles/styleset/margin/mt40'
import { mb24 } from '../../__aeki__/styles/styleset/margin/mb24'
import { mb8 } from '../../__aeki__/styles/styleset/margin/mb8'
import { pb12 } from '../../__aeki__/styles/styleset/padding/pb12'
import { textCenter } from '../../__aeki__/styles/styleset/text-center/textCenter'
import { flexi } from '../../__aeki__/styles/styleset/flex/flexi'
import { hide } from '../../__aeki__/styles/styleset/hide/hide'
import { w100 } from '../../__aeki__/styles/styleset/width/w100'
import { mt16 } from '../../__aeki__/styles/styleset/margin/mt16'
import { pt12 } from '../../__aeki__/styles/styleset/padding/pt12'
import { mb4 } from '../../__aeki__/styles/styleset/margin/mb4'
import { mr4 } from '../../__aeki__/styles/styleset/margin/mr4'
import { rowmdi } from '../../__aeki__/styles/styleset/row/rowmdi'
import { flexmdi } from '../../__aeki__/styles/styleset/flex/flexmdi'
import { pb32 } from '../../__aeki__/styles/styleset/padding/pb32'
import { pb80 } from '../../__aeki__/styles/styleset/padding/pb80'
import { pt80 } from '../../__aeki__/styles/styleset/padding/pt80'
import { pt32 } from '../../__aeki__/styles/styleset/padding/pt32'
import { mt24 } from '../../__aeki__/styles/styleset/margin/mt24'
import { mt8 } from '../../__aeki__/styles/styleset/margin/mt8'
import { pt64 } from '../../__aeki__/styles/styleset/padding/pt64'
import { pb64 } from '../../__aeki__/styles/styleset/padding/pb64'
import { textCenteri } from '../../__aeki__/styles/styleset/text-center/textCenteri'
import { mt36 } from '../../__aeki__/styles/styleset/margin/mt36'
import { mt44 } from '../../__aeki__/styles/styleset/margin/mt44'
import { mt48 } from '../../__aeki__/styles/styleset/margin/mt48'
import { f20i } from '../../__aeki__/styles/styleset/font-size/f20i'
import { Loading } from '../../__aeki__/components/loading'
import { mb12 } from '../../__aeki__/styles/styleset/margin/mb12'
import { pt96 } from '../../__aeki__/styles/styleset/padding/pt96'
import { pt104 } from '../../__aeki__/styles/styleset/padding/pt104'
import { mb64 } from '../../__aeki__/styles/styleset/margin/mb64'

const HomeCard = (props: any) => {
  const {
    className,
    title,
    subTitle,
    description,
    actionLabel,
    actionUrl,
    component,
    flip,
    swap,
  } = props

  const STYLES = stylesheet({
    container: {
      maxWidth: 450,
      paddingRight: 16,
      ...createMediaQuery.md({
        maxWidth: 'none',
        paddingRight: 0,
      }),
    },
    containerComponent: {
      maxWidth: 450,
      ...createMediaQuery.md({
        maxWidth: 'none',
      }),
    },
  })

  return (
    <Box
      baseline="none"
      className={classes(className, rowmdi, pb80, pt80)}
      flex
      spaceBetween
      alignCenterY
      style={{
        flexDirection: swap ? 'row-reverse' : 'row',
        zIndex: 2,
      }}
      // minHeight={fullHeight ? '50vh' : 'initial'}
    >
      <VisibleFade>
        <Box className={STYLES.container} baseline="none">
          <Box flex baseline="none">
            {/* <i className="material-icons-two-tone">family_restroom</i> */}
            <Text baseline={flip ? 'primary' : 'surface'} size="md" semiBold uppercase>
              {title}
            </Text>
          </Box>
          <Heading
            baseline={flip ? 'primary' : 'surface'}
            size="xl"
            className={classes(mt16, style({ display: 'inherit' }))}
          >
            {subTitle}
          </Heading>
          <Box
            row
            minWidth={80}
            maxWidth={80}
            baseline="none"
            className={style({
              marginTop: 28,
              marginBottom: 28,
            })}
          >
            <VisibleSize dimension="width">
              <Box baseline="none" minHeight={1} minWidth={1} flex row>
                <Line thick dark className={style({ background: 'rgb(120,120,120) !important' })} />
              </Box>
            </VisibleSize>
          </Box>
          <Box className={mb24} baseline="none" maxWidth={716}>
            <Text
              baseline={flip ? 'primary' : 'surface'}
              className={classes(w100, flexi)}
              size="lg"
              lighter={!flip}
              readable
            >
              {description}
            </Text>
          </Box>
          <Box baseline="none" flex>
            <Link to={actionUrl}>
              {!flip ? (
                <Button label={actionLabel} baseline="secondary" size="lg" square />
              ) : (
                <Button
                  style={{
                    background: '#fece14',
                  }}
                  label={actionLabel}
                  baseline="surface"
                  size="lg"
                  square
                />
              )}
            </Link>
          </Box>
        </Box>
      </VisibleFade>
      <Box
        baseline="none"
        className={classes(STYLES.container, hide, flexmdi, rowmdi, mt24, mb24)}
        fullWidth
      ></Box>
      <Box baseline="none" className={STYLES.containerComponent} row rounded fullWidth>
        <Box baseline="none" rounded>
          <VisibleFade direction="bottom">{component}</VisibleFade>
        </Box>
      </Box>
    </Box>
  )
}

const HomeCardAlt = (props: any) => {
  const { className } = props

  return (
    <Box className={className} flex alignCenterX fullWidth>
      <Box baseline="none" row fullWidth>
        <Heading size="xs" font="primary" semiBold tag="h2" uppercase shrink>
          How we make an impact
        </Heading>
        {/* <Box
          row
          minWidth={80}
          maxWidth={80}
          baseline="none"
          className={style({
            marginTop: 32,
            marginBottom: 0,
          })}
          alignCenterX
        >
          <VisibleSize dimension="width">
            <Box baseline="none" minHeight={1} minWidth={1} flex row>
              <Line thick dark className={style({ background: 'rgb(120,120,120) !important' })} />
            </Box>
          </VisibleSize>
        </Box> */}
      </Box>
    </Box>
  )
}

const RecentChildCares = (props: any) => {
  const INTERVAL = 10000
  const interval: any = useRef(null)
  const [index, $index] = useCounter(0, { min: 0, max: 2 })
  const [hovered, $hovered] = useValue(false)
  const [trigger, $trigger] = useValue(false)
  const [triggerNavigation, $triggerNavigation] = useValue(false)
  const [isLoading, $isLoading] = useValue(false)
  const [post, $post] = useFetch({ data: [] })

  const items = [
    {
      label: 'Vancouver, BC, Canada',
      placeId: 'vancouver',
    },
    {
      label: 'Burnaby, BC, Canada',
      placeId: 'burnaby',
    },
    {
      label: 'Langley, BC, Canada',
      placeId: 'langley',
    },
  ]

  const start = async (disableLoad?: boolean) => {
    clearInterval(interval)
    if (!disableLoad) {
      $trigger.set(Date.now())
    } else {
      $trigger.set(Date.now())
    }
  }

  useEffect(() => {
    if (post.mounted) {
      start()
    }

    return () => {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [post.mounted])

  useEffect(() => {
    $isLoading.set(true)
    const run = async () => {
      clearInterval(interval.current)
      await $post
        .get({
          url: `${process.env.REACT_APP_API_URL}/a/home/recent-updates/${items[index].placeId}`,
        })
        .then(() => {
          $isLoading.set(false)
          interval.current = setInterval(() => {
            $index.increment()
            start()
          }, INTERVAL)
        })
    }

    if (trigger && post.mounted) {
      run()
    }
  }, [trigger, post.mounted])

  useEffect(() => {
    $isLoading.set(true)
    const run = async () => {
      clearInterval(interval.current)
      await $post
        .get({
          url: `${process.env.REACT_APP_API_URL}/a/home/recent-updates/${items[index].placeId}`,
        })
        .then(() => {
          $isLoading.set(false)
        })
    }

    if (triggerNavigation && post.mounted) {
      run()
    }
  }, [triggerNavigation, post.mounted])

  let className

  if (hovered) {
    className = style({
      width: '100%',
      background: '#3f73d6',
    })
  } else if (isLoading) {
    className = style({
      width: '100%',
      background: '#fece14',
    })
  } else {
    className = style({
      background: '#3f73d6',
      animationDuration: `${INTERVAL}ms`,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      animation: keyframes({
        from: {
          width: '0%',
        },
        to: {
          width: '100%',
        },
      }),
    })
  }

  return (
    <Box baseline="surface" row border rounded>
      <Box
        className={classes(paddingVerticalOuter, pt12, pb12)}
        baseline="none"
        flex
        spaceBetween
        alignCenterY
        rounded
      >
        <Box baseline="none" row>
          <Text className={mb4} size="xs">
            Recently updated childcare in
          </Text>
          <Text size="lg" semiBold>
            {items[index].label}
          </Text>
        </Box>
        <Box flex>
          <Button
            onClick={() => {
              $index.decrement()
              $triggerNavigation.set(Date.now())
              $hovered.set(true)
            }}
            baseline={isLoading ? 'disabled' : 'background'}
            className={mr4}
            size="sm"
            icon={<span className={classes('material-icons', f18i)}>keyboard_arrow_left</span>}
          />
          <Button
            onClick={() => {
              $index.increment()
              $triggerNavigation.set(Date.now())
              $hovered.set(true)
            }}
            baseline={isLoading ? 'disabled' : 'background'}
            size="sm"
            icon={<span className={classes('material-icons', f18i)}>keyboard_arrow_right</span>}
          />
        </Box>
      </Box>
      <Box style={{ background: 'rgb(250,250,250)' }} minHeight={4} fullWidth relative>
        <Box minHeight={4} className={className} absolute />
      </Box>
      <Line thick />
      <Box
        className={classes(paddingVerticalOuter, pt12, pb12)}
        baseline="background"
        minHeight={240}
        rounded
        row
      >
        {isLoading ? (
          <Skeleton height={63.38} />
        ) : (
          post.payload.data.map((item: any, key: any) => {
            return (
              <Box
                className={classes(paddingVerticalOuter, pt12, pb12, key !== 0 ? mt8 : '')}
                key={key}
                shadow
                rounded
                flex
                spaceBetween
                alignCenterY
              >
                <Box
                  row
                  className={style({
                    width: 'calc(100% - 34px)',
                  })}
                >
                  <Text
                    size="lg"
                    semiBold
                    className={style({
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '94%',
                      whiteSpace: 'nowrap',
                    })}
                  >
                    {item.body.generalInformation.name}
                  </Text>
                  <Text size="xs" semiBold>
                    {item.body.generalInformation.addressLine1}
                  </Text>
                </Box>
                <Link
                  to={`/a/childcares?center.0=${`${item.location.coordinates[0]}`.replace(
                    '.',
                    '_',
                  )}&center.1=${`${item.location.coordinates[1]}`.replace('.', '_')}&page=1`}
                >
                  <Button
                    baseline="background"
                    className={mr4}
                    size="sm"
                    icon={<i className={classes('material-icons', f18i)}>search</i>}
                  />
                </Link>
              </Box>
            )
          })
        )}
      </Box>
    </Box>
  )
}

const Video = (props: any) => {
  return (
    <ReactVisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return <video {...props} />
      }}
    </ReactVisibilitySensor>
  )
}

const FeatureSlider = (props: any) => {
  const {
    flip,
    items = [
      {
        label: 'Put your childcare on the map',
      },
      {
        label: 'Manage inquiries and waitlist more effectively',
      },
      {
        label: 'Organize your clients and children',
      },
    ],
  } = props
  const [index, $index] = useCounter(0, { min: 0, max: 2 })

  const className = style({
    background: '#3f73d6',
    width: '100%',
  })

  const renderItem = (i: any, isLast?: boolean) => {
    return (
      <Box row key={i}>
        <Box
          baseline="none"
          row
          className={classes(paddingVerticalOuter, style({ cursor: 'pointer' }))}
          minHeight={48}
          alignCenterY
          onClick={() => {
            $index.set(i)
          }}
          relative
        >
          <Text size="lg">{items[i].label}</Text>
          {i === index && (
            <Box
              style={{
                background: flip ? 'rgb(230,230,230)' : 'rgb(250,250,250)',
                bottom: 0,
                left: 0,
              }}
              minHeight={4}
              fullWidth
              relative
              absolute
            >
              <Box minHeight={4} className={className} absolute />
            </Box>
          )}
        </Box>
        <Box
          row
          className={classes(
            style({
              overflow: 'hidden',
              transition: 'min-height .4s ease',
            }),
            i === index
              ? style({
                  minHeight: 320,
                  maxHeight: 320,
                  $nest: {
                    ...createMediaQuery.md({
                      minHeight: 'fit-content',
                      maxHeight: 'fit-content',
                    }),
                  },
                })
              : style({
                  minHeight: 0,
                  maxHeight: 0,
                }),
          )}
          style={{
            background: flip ? '#211d2e' : '#f1f0f1',
          }}
        >
          {i === index && <Video {...items[i].video} muted autoPlay playsInline thick />}
        </Box>
        {!isLast && <Line thick />}
      </Box>
    )
  }

  return (
    <Box
      baseline="none"
      row
      border={!flip}
      className={style({
        height: 48 * items.length + 324,
        overflow: 'hidden',
        $nest: {
          ...createMediaQuery.md({
            height: 'initial',
          }),
        },
      })}
      rounded
      onMouseEnter={() => {
        // clearInterval(interval.current)
        // $hovered.set(true)
      }}
      onMouseLeave={() => {
        // clearInterval(interval.current)
        // interval.current = setInterval(() => {
        //   $index.increment()
        // }, INTERVAL)
        // $hovered.set(false)
      }}
    >
      {/* <ReactVisibilitySensor partialVisibility>
        {({ isVisible }) => {
          if (isVisible) {
            clearInterval(interval.current)
            interval.current = setInterval(() => {
              $index.increment()
            }, INTERVAL)
          } else {
            clearInterval(interval.current)
          }

          return <span style={{ opacity: 0, position: 'absolute' }}>temp</span>
        }}
      </ReactVisibilitySensor> */}
      {items.map((item: any, key: any) => {
        return renderItem(key, key === items.length - 1)
      })}
    </Box>
  )
}

/* <Home /> ===================================================================================== */
const Home: FC<any> = (props: any) => {
  const { eject } = props
  const history = useNavigate()
  const [isSearching, $isSearching] = useValue(0)

  useEffect(() => {
    return () => {
      eject()
    }
  }, [])

  const handleSearch = (index: number, query?: string) => () => {
    $isSearching.set(index)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        const { coords } = position
        if (coords) {
          history(
            `/a/childcares?center.0=${`${coords.longitude}`.replace(
              '.',
              '_',
            )}&center.1=${`${coords.latitude}`.replace('.', '_')}&page=1${
              query ? `?${query}` : ''
            }`,
          )
        }
      })
    }
  }

  const renderContentSelector = () => {
    return (
      <>
        <Box baseline="secondary" relative style={{ overflow: 'hidden' }}>
          <img
            src="/images/background.png"
            style={{
              position: 'absolute',
              right: 0,
              transform: 'translate(50%, -50%)',
              opacity: 0.4,
              zIndex: 1,
            }}
          />
          <Box baseline="surface" style={{ borderRadius: '24px 24px 0 0', overflow: 'hidden' }}>
            <Box
              className={classes(pt104, pb80, paddingVerticalOuter)}
              baseline="none"
              maxWidth={1022}
              row
              relative
              centerAutoX
            >
              <HomeCardAlt />
              <HomeCard
                title="For parents"
                subTitle="A simple but powerful search tool meant to help parents find the perfect childcare."
                description="Every family deserves to find the right childcare that fits their families unique needs. Seamlessly filter between locations, age group, curriculum or even language."
                actionLabel="Launch search tool"
                actionUrl="/a/childcares?page=1"
                component={<RecentChildCares />}
              />
            </Box>
          </Box>
          <Box baseline="surface">
            <Box
              baseline="background"
              style={{ borderRadius: '24px 24px 0 0', overflow: 'hidden' }}
            >
              <Box
                className={classes(pt32, pb32, paddingVerticalOuter)}
                maxWidth={1022}
                row
                relative
                centerAutoX
                baseline="none"
              >
                <HomeCard
                  title="For Resource and Community Centers"
                  subTitle="An efficient platform to manage and market a group of childcare."
                  description="Manage and market your network of childcare. Ensure referrals and waitlists are always up to date in your network to improve reliability with parents."
                  actionLabel="Contact us"
                  actionUrl="/a/contact-us"
                  component={
                    <FeatureSlider
                      flip
                      items={[
                        {
                          label: 'Manage childcare in your area',
                          video: {
                            src:
                              'https://childat-io-storage.sfo3.cdn.digitaloceanspaces.com/public/ccrr-feature-1.mp4',
                          },
                        },
                        {
                          label: 'Organize openings and share them on your platform',
                          video: {
                            src:
                              'https://childat-io-storage.sfo3.cdn.digitaloceanspaces.com/public/ccrr-feature-2.mp4',
                          },
                        },
                        {
                          label: 'Communicate with childcare',
                          video: {
                            src:
                              'https://childat-io-storage.sfo3.cdn.digitaloceanspaces.com/public/ccrr-feature-3.mp4',
                            loop: true,
                          },
                        },
                      ]}
                    />
                  }
                  fullHeight
                  swap
                  // flip
                />
              </Box>
            </Box>
          </Box>
          <Box baseline="background">
            <Box baseline="surface" style={{ borderRadius: '24px 24px 0 0', overflow: 'hidden' }}>
              <Box
                maxWidth={1022}
                row
                className={classes(pt32, pb32, paddingVerticalOuter)}
                relative
                centerAutoX
              >
                <HomeCard
                  title="For Childcare"
                  subTitle="An all-in-one tool to manage and market your childcare."
                  description="Utilize our platform to manage your waitlists, boost enrollments, manage staff and children. Easily complete your administrative tasks to give staff peace of mind when providing quality care."
                  actionLabel="Claim your childcare"
                  actionUrl="/a/claim-your-childcare"
                  component={
                    <FeatureSlider
                      items={[
                        {
                          label: 'Put your childcare on the map',
                          video: {
                            src:
                              'https://childat-io-storage.sfo3.cdn.digitaloceanspaces.com/public/childcare-feature-1.mp4',
                          },
                        },
                        {
                          label: 'Manage your clients and waitlist with ease',
                          video: {
                            src:
                              'https://childat-io-storage.sfo3.cdn.digitaloceanspaces.com/public/childcare-feature-2.mp4',
                            loop: true,
                          },
                        },
                      ]}
                    />
                  }
                  fullHeight
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  const promotion = useMemo(
    () => (
      <>
        <Line />
        <Box baseline="background" className={classes(paddingVerticalOuter, pt64, pb64)}>
          <VisibleFade>
            <Box baseline="none" maxWidth={800} centerAutoX row alignCenterX>
              <Heading size="xs" className={classes(mb12, textCenter)} semiBold crank>
                Solutions for connecting parents, childcare, and communities with one another.
              </Heading>
              <Box className={mb24} baseline="none">
                <Heading size="sm">Contact us for more information.</Heading>
              </Box>
              <Link to={'/a/contact-us'}>
                <Button label="Contact us" baseline="primary" size="lg" square />
              </Link>
            </Box>
          </VisibleFade>
        </Box>
      </>
    ),
    [],
  )

  return (
    <AppLayout.render
      head={{
        title: 'Find the perfect childcare and day care for your family - ChildAt.io',
        description:
          'Choosing a childcare is a big decision with many different considerations. We aim to equip the parents with information and the tools to find the right childcare for their child.',
        mainImage:
          'https://storage.googleapis.com/childat-public-storage/images/home/childat-thumbnail.jpg',
      }}
      headerProps={{
        scrollEffect: true,
        shadow: true,
        allowToggleEffect: true,
        maxWidth: MAX_WIDTH,
        top: 80,
      }}
      content={
        <Box
          baseline="secondary"
          fullHeight
          row
          flex
          style={{
            marginTop: -54,
            overflow: 'hidden',
          }}
          relative
        >
          <Box baseline="none" relative>
            <div
              style={{
                width: 0,
                height: 0,
                position: 'absolute',
                right: -400,
                bottom: 400,
                zIndex: 1,
              }}
            >
              <img
                src="/images/background.png"
                style={{
                  position: 'absolute',
                  right: 0,
                  opacity: 0.4,
                  zIndex: 1,
                }}
              />
            </div>
            <Box
              className={classes(
                paddingVerticalOuter,
                style({
                  overflow: 'hidden',
                  paddingTop: 162,
                  paddingBottom: 120,
                  zIndex: 2,
                }),
              )}
              baseline="none"
              maxWidth={MAX_WIDTH}
              centerAutoX
              fullWidth
              row
              relative
            >
              {/* Header Slogan */}
              <Box
                // className={classes(pt80, pb120)}
                baseline="none"
                alignCenterY
                alignCenterX
                column
                // row
                fullWidth
              >
                <Box className={classes(w54, w100md)} baseline="none" row alignCenterX>
                  <Box row baseline="none" fullWidth alignCenterX>
                    <VisibleFade>
                      <Box baseline="none">
                        <Title
                          size="xl"
                          font="primary"
                          tag="h1"
                          className={textCenteri}
                          crank
                          semiBold
                        >
                          Find the right <br />
                          <span style={{ position: 'relative' }}>
                            childcare
                            <svg
                              style={{
                                position: 'absolute',
                                bottom: -20,
                                left: 0,
                                width: '100%',
                                height: 20,
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 120 8"
                            >
                              {/* <circle cx={4} cy={1.5} r={2} fill="#7ea1f7" /> */}
                              <path
                                d={`M0 4 C ${(60 / 4) * 1} 8, ${(60 / 4) * 3} 8, ${(60 / 4) *
                                  4} 4 S ${(60 / 4) * 3 + 60} 0 ${(60 / 4) * 4 + 60} 4`}
                                fill="none"
                                stroke="#7ea1f7"
                                strokeWidth="2"
                                strokeDasharray={'7'}
                                strokeDashoffset={12}
                                strokeLinecap="round"
                              />
                            </svg>
                          </span>{' '}
                          for your family
                        </Title>
                      </Box>
                      <Box baseline="none" row fullWidth alignCenterX className={mt48}>
                        <VisibleFade>
                          <Box baseline="none" fullWidth maxWidth={440} row>
                            <Text size="xl" baseline="primary" readable className={textCenteri}>
                              We are constantly connecting with childcare to provide you the most
                              up-to-date information.
                            </Text>
                          </Box>
                          <Box baseline="none" flex alignCenterX style={{ marginTop: 32 }}>
                            <Button
                              size="xl"
                              label={
                                isSearching === 1 ? ' Searching ...' : 'Search your neighbourhood'
                              }
                              icon={
                                isSearching === 1 ? (
                                  <Loading size="md" />
                                ) : (
                                  // <Loading size="md" />
                                  <i className={classes('material-icons', f20i)}>search</i>
                                )
                              }
                              baseline="primary"
                              onClick={handleSearch(1)}
                              square
                            />
                          </Box>
                        </VisibleFade>
                      </Box>
                    </VisibleFade>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Content Selector */}
          <Box baseline="background" style={{ zIndex: 1 }} relative>
            <Box>{renderContentSelector()}</Box>
            {promotion}
            <Line thick className={classes(mb64)} />
            <Footer />
          </Box>
        </Box>
      }
    />
  )
}

cssRaw(`
.custom-shape-divider-top-1651932675 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1651932675 svg {
  position: relative;
  display: block;
  width: calc(190% + 1.3px);
  height: 63px;
}

.custom-shape-divider-top-1651932675 .shape-fill {
  fill: #F3F3F3;
}
`)

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  container: {
    height: 'calc(100vh - 100px)',
    $nest: {
      ...createMediaQuery.md({
        height: 'calc(50vh - 52px)',
      }),
    },
  },
  containerHeaderSlogan: {
    width: 'fit-content',
    alignItems: 'center',
  },
  contentSearchInnerContainer: {
    width: 'fit-content',
    alignItems: 'center',
  },
  containerHeaderImage: {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    $nest: {
      ...createCustomMediaQuery({ maxWidth: 900, style: { width: '100%', marginBottom: 12 } }),
      img: {
        width: '80%',
        height: 'auto',
        ...createCustomMediaQuery({
          maxWidth: 900,
          style: {
            width: '100%',
          },
        }),
        ...createCustomMediaQuery({
          maxWidth: 540,
          style: {
            width: '100%',
          },
        }),
      },
    },
  },
  textSlogan: {
    fontSize: 42,
    display: 'flex',
    fontWeight: 600,
    textAlign: 'center',
    $nest: {
      '.MuiSvgIcon-root': {
        fontSize: 13,
        position: 'absolute',
        bottom: 10,
        left: 2,
        color: '#ffcd15',
        ...createCustomMediaQuery({
          maxWidth: 900,
          style: { bottom: 4.5 },
        }),
      },
    },
  },
  textSloganDescription: {
    letterSpacing: '0.2px',
    marginBottom: 16,
    marginTop: 4,
    textAlign: 'center',
    $nest: {
      ...createCustomMediaQuery({
        maxWidth: 900,
        style: { fontSize: 18, marginBottom: 16 },
      }),
    },
  },
  buttonSearch: {
    width: '64px !important',
  },
  iconSpin: {
    color: 'black !important',
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    animationName: keyframes({
      '100%': {
        transform: 'rotate(360deg)',
      },
    }),
  },
  animateButton1: {
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    animationName: keyframes({
      '0%': {
        transform: 'translateX(0px)',
      },
      '50%': {
        transform: 'translateX(-8px)',
      },
      '100%': {
        transform: 'translateX(0px)',
      },
    }),
  },
  animateButton2: {
    animationDuration: '4s',
    animationIterationCount: 'infinite',
    animationName: keyframes({
      '0%': {
        transform: 'translateX(0px)',
      },
      '50%': {
        transform: 'translateX(8px)',
      },
      '100%': {
        transform: 'translateX(0px)',
      },
    }),
  },
})

export default Home
